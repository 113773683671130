
.u-mobile-toggle {
	display: none;
	width: 40px;
	height: 40px;
	background: #666;
}

@include mq(mobile, desktop) {
	
	.u-mobile-toggle {
		display: block;
	}

	.u-mobile-toggle__target {
		display: none;
	}

	.u-mobile-toggle:checked ~ .u-mobile-toggle__target {
		display: block;
	}

	.u-mobile-toggle:checked ~ .u-mobile-toggle__target {
		display: block;
	}
}


.u-hidden {
	display: none;
}

.u-visible--mobile {
	@include mq(mobile, mobileLandscape) {
		display: block;
	}
}

.u-visible--mobileLandscape {
	@include mq(mobileLandscape, tablet) {
		display: block;
	}
}

.u-visible--tablet {
	@include mq(tablet, desktop) {
		display: block;
	}
}