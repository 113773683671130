.m-text-image-pair {
	display: grid;
	@include mq($from: tablet) {
		grid-template-columns: 1fr 40px 1fr;
		grid-template-areas: "ipLeft gap ipRight";
		grid-column-gap: 40px;
	}
	@include mq($until: tablet) {
		grid-template-areas: "ipLeft" "ipRight";
		grid-row-gap: 40px;
	}
}

.m-text-image-pair--textThenImage {
	@include mq($from: tablet) {
		grid-template-areas: "ipRight gap ipLeft";
		grid-column-gap: 40px;
	}
	@include mq($until: tablet) {
		grid-template-areas: "ipRight" "ipLeft";
		grid-row-gap: 40px;
	}
}

.m-text-image-pair__image {
	grid-area: ipLeft;
}  

.m-text-image-pair__copy {
	grid-area: ipRight; 
	display: flex;
	flex-direction: column;
	justify-content: center;
}
