// Hero element
.m-hero {
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: cover; 
 	background-position: center;
} 

.m-hero__content {
	
	* {
		color: white;
	}
	
	background: rgba($blue, 0.7);
	font-family: $serif;
	text-transform: uppercase;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	@include mq($from: desktop) {
		padding: 40px 60px;
		top: -10%;
	}
	@include mq($until: desktop) {
		max-width: 300px; 
		min-height: 96px;
		min-width: 268px;
		.l-hero__hr, .l-hero__list {
			display: none;
		}
	}
	@include mq(tablet, desktop) {
		max-width: 600px;
	}
}

.l-hero__heading {
	margin: 0;
}