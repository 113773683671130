
.m-entry-card {
	//display: flex;
	//flex-direction: column;
	//grid-template-areas: "image" "content";
	//grid-template-rows: 217px auto;	
	background: white;
	display: flex;
	flex-direction: column;
}

.m-entry-card__image {
	//grid-area: image;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.m-entry-card__content {
	//grid-area: content;
	max-width: 100%;
	padding: 32px 39px;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	display: flex;
	background: white;
	@include mq(desktop) {
		align-items: center;
		text-align: center;
	}
}