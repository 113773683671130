/**
 * "Big" layout changes go in here, e.g. the overall grid layout structure and
 * any generic grid/layout classes intended to be reused throughout
 */

$container-width: 1440px;
$inner-container-width: 1080px;

.l-container {	
	display: grid;

	overflow-x: hidden;
	grid-template-columns: 
		minmax(30px, 1fr)
		minmax(0px, 1440px)
    	minmax(30px, 1fr)
        ;
        				// nav/header, hero, searchbar, content & footer
	grid-template-rows: 100px 700px 68px auto auto;

	@include mq($until: tablet) {
		// mobile view - narrower hero, auto search area
		grid-template-rows: 100px 215px auto auto auto;	
	}

	@include mq($from: tablet, $until: desktop) {
		// tablet view, same height hero, auto search area
		grid-template-rows: 100px 215px auto auto auto;	
	}

	grid-template-areas:   
		"header header header"
		"hero hero hero"
		". search ."
		". content ."
		"footer footer footer";
}


.l-container--sub {

	@include mq(mobile, desktop) {
		// mobile view - narrower hero, auto search area
		grid-template-rows: 100px 215px auto auto auto;	
	}

	@include mq(desktop) {
		// tablet view, same height hero, auto search area
		grid-template-rows: 100px 370px 68px auto auto;	
	}
}


//https://medium.com/@igloude/how-css-grid-makes-single-columns-better-def5a7dd5045

.l-header {

	grid-area: header;
	background: white;
	z-index: 350;

}

.l-header__inner {
	max-width: 1240px;
	margin: 0 auto;
	height: 100%;

	padding: 0 30px;
	//grid-column: lg-container;
	display: flex;
	flex-direction: row;
	@include mq(desktop) {
		align-items: stretch;
	}
	@include mq(mobile, desktop) {
		align-items: center;
	}
	justify-content: space-between;
}


@include mq(mobile, desktop) {
	.l-header__nav {
		position: absolute;
		z-index: 100;
		width: 100%;
		left: 0px;
		top: 100px;
		box-sizing: border-box;
	}
}


.l-header-nav__detail {
	display: flex;
	> * {
		margin-bottom: 20px;
	}
}


.l-logo {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.l-hero {
	grid-area: hero;
	background: #999;
}
 
.l-search {
	grid-area: search;
	position: relative;
	top: -34px;
	padding-bottom: -34px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include mq($until: desktop) {
		top: auto;
		padding-bottom: auto;
		flex-direction: column;
	}
}

.l-search--fixed {
	position: fixed;
	top: 20px;
	//left: 50%;
    //transform: translateX(-50%);
    min-width: 980px;
    max-width: 1215px;
    z-index: 500;
}

.l-content {
	grid-area: content;
	display: flex;
	flex-direction: column;
	flex-wrap: no-wrap;
	align-items: center;

	/*display: grid;
	grid-template-columns: 100px 200px 1fr 200px 100px;

	// Content specific layout columns
	.l-column--full {
		grid-column-start: 1;
		grid-column-end: 6;
	}

	.l-column--medium {
		grid-column-start: 2;
		grid-column-end: 4;
	}

	.l-column--small {
		grid-column-start: 3;
		grid-column-end: 4;
	}*/

}


.l-footer {
	grid-area: footer;
}

.l-footer__content {
	width: calc(100% - 30px);
	max-width: $inner-container-width;
	margin: 0 auto;
	display: grid;
	grid-template-columns:
		310px
		435px
		minmax(1fr, 595px)
		minmax(1fr, 100px)		
		;

	grid-template-rows: 1fr 1fr;

	grid-column-gap: 20px;

	@include mq(tablet) {
		grid-row-gap: 64px;
		grid-template-areas:
			"address contact nav nav"
			"logo logo subnav social";
	}

	@include mq(mobile, tablet) {
		grid-row-gap: 18px;
		margin: 0 32px;
		grid-template-areas:
			"logo" "address" "contact" "nav" "social" "subnav";
	}

	order: 5;	
}

.l-footer__address {
	grid-area: address;
}

.l-footer__contact {
	grid-area: contact;
}

.l-footer__nav {
	grid-area: nav;
}

.l-footer__logo {
	grid-area: logo;
}

.l-footer__subnav {
	grid-area: subnav;
}

.l-footer__social {
	grid-area: social;
}





/**
 * Generic layouts
 */


// For placing content inside a full-span element


// Todo - create a generic grid class based on this...

.l-related {
	display: grid;
	@include mq(desktop) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: "r1 r2 r3";
		> *:nth-child(1) {
			grid-area: r1;
		}
		> *:nth-child(2) {
			grid-area: r2;
		}
		> *:nth-child(3) {
			grid-area: r3;
		}
	}
	
	grid-gap: 24px;
	
}

.l-index {
	display: grid;
	@include mq(tablet) {
		grid-template-columns: repeat(3, 33.33%);
	}
	/*@include mq(tablet, , tablet) {
		grid-template-columns: repeat(2, 1fr);
	}*/
	@include mq(tablet, desktop) {
		grid-template-columns: repeat(2, 50%);	
	}
	grid-gap: 24px;
}

.l-index--people {
	grid-column-gap: 36px;
	grid-row-gap: 100px;
}

.l-grid {
	display: grid;
}

.l-grid--5 {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 36px;

	/*@include mq(mobile) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mq($from: mobileLandscape, $until: tablet) {
		grid-template-columns: repeat(3, 1fr);
	}*/
}