
.m-case-study {	

	display: grid;
	
	@include mq($until: tablet) {
		grid-template-areas: "cs-image" "cs-content";
		grid-row-gap: 20px;
	}

	@include mq($from: tablet) {
		grid-template-areas: "cs-image cs-content";
		grid-template-columns: 50% 50%;
		grid-column-gap: 40px;
	}
}

.m-case-study__image {
	grid-area: cs-image; 
}

.m-case-study__content {
	grid-area: cs-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	//align-items: center;
}