/**
 * Header template
 */
.m-nav {

	display: flex;
	flex-direction: row;

	height: 100%;

	
	li:not(:first-child) {
		//margin-left: 28px;
	}

	& > li > ul {
		// Don't allow dropdowns in any other nav
		//display: none;
		//position: absolute;
		//transform: translate(-50%, 0);
	}
}

@include mq(mobile, desktop) {

	.m-nav__subtoggle {
		//display: none;
		position: relative;
	}

	.m-nav__subtoggle:checked ~ .m-nav__subtoggle-label {
		background-image: url(../img/icons/Upward.svg);
	}

	.m-nav__subtoggle-label {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 0px;
		top: 8px;
		background: url(../img/icons/Downward.svg) no-repeat;
		background-size: contain;
		background-position: center;
	}
}



.m-nav--header__burger {
 
	width: 50px;
	height: 50px;
	background: #000;
	border: 0;



	@include mq($from: desktop) {
		//display: none;
	}

}

.m-nav--header__burger:checked ~ .m-nav--header {
	display: block;
}

#headertoggle:checked ~ .t-header__blackout {
	//display: block;
}

.m-nav--header {


	height: 100%;
	position: relative;

	a {
		color: $darkGrey;
	}

	@include mq($until: desktop) {
		
		a {
			color: inherit;
		}

		height: auto;

		background: white;
		z-index: 100;

		margin-top: 30px;

		display: flex;
		flex-direction: column;

		li {
			margin: 0.45em 0;
			position: relative;
		}

		.m-nav__subtoggle {
			opacity: 0;
			pointer-events: none;
		}

		& > li {

			position: relative;

			& > a {
				font-size: 2.8rem;
				line-height: 3.8rem;
			}



			.u-mobile-toggle {
				position: absolute;
				right: 0px;
				top: 0px;
				width: 40px;
				height: 40px;
			}

			> ul {
				margin-left: 0px;
				padding-left: 0px;
			}

			& li {
				&:hover {
					background: #F8F8F8;
				}
				& > a {
					font-size: 2.2rem;
					line-height: 3.2rem;
					color: #737373;
					text-indent: 2rem;
					display: flex;
					flex-direction: row;
					align-items: center;
					&:before {
						content: "";
						display: block;
						width: 10px;
						position: relative;
						top: 1px;
						height: 2px;
						margin-right: 11px;
						background: #C4C4C4;
					}
				}
			}
		}
	}

	@include mq($from: desktop) {

		& > li {

			> a {
				height: 100%;
				padding: 0 14px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $darkGrey;
			}

			height: 100%;
			position: relative;

			display: flex;
			flex-direction: column;
			align-items: center;
			align-content: center;

			&:after, &.m-nav__li--active:after {
				content: "";
				display: block;
				height: 4px;
				width: 10%;
				opacity: 0;
				left: 45%;
				transition: all 0.25s ease-in-out;
				background: $blue;
				position: absolute;
				bottom: 2px;
			}

			&.m-nav__li--active:after {
				width: 80%;
				left: 10%;
				opacity: 1;
			}

			&:hover {

				&:after {
					width: 80%;
					left: 10%;
					opacity: 1;
				}

				& > ul {
					opacity: 1;
					pointer-events: all;

				}

			};

			& > ul {

				//display: none;

				// subtle transition effects
				transition: 
					//transform 0.15s ease-in-out,
					opacity 0.1s ease-in-out;

				//transform: translate(0, -1%);
				opacity: 0;
				pointer-events: none;

				position: absolute;
				box-shadow: 0px 7px 10px -2px rgba(0,0,0,0.75);
				z-index: 100;
				top: 100%;

				background: white;

				li {

					min-height: 44px;
					border-bottom: 1px solid $grey;

					transition: background 0.25s ease-in-out;

					/*display: flex;
					flex-direction: column;
					justify-content: center;*/
					
					&:hover {
						background: #F8F8F8;
					}

					@include mq(tablet) {
						ul {
							//display: none;
						}
					}

					> ul {
						border-top: 1px solid $grey;
						li:last-child {
							border-bottom: none;
						}

						a {
							&:before {
								content: "- ";
								margin-right: 10px;
							}
						}
					}

					> a {
						white-space: nowrap;					
						display: flex;
						align-items: center;
						//justify-content: center;
						height: 44px;
						width: 100%;

						padding: 0 1em;
						/*display: block;
						*/
					}
				}
			}
		}
	}

}