.m-people {
	display: grid;
	//grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(auto-fill, minmax(min-content, 100px));
	grid-column-gap: 36px;
	grid-row-gap: 100px;
	
	@include mq(mobile, tablet) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	}
}

.m-person {
	display: grid;
	grid-template-areas: "person-image" "person-copy";
	border: 1px solid $grey;
	background: white;
	text-align: center;
}

.readmore-people {
	height: 120px;
	overflow: hidden;
}

.readmore-people.visible {
	overflow: visible;
	height: auto;
}


@include mq(desktop) {
	
	.m-person--featured {

		grid-column-start: 1;
		grid-column-end: 4;	

		grid-template-areas: "person-image person-copy";

		.m-person__image img {
			//max-width: 215px; 
			//max-height: 215px;
			max-width: 210px;
			max-height: 210px;
			margin: 50px;
			border-radius: 215px;
			-webkit-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5); 
			box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5);
		}

		.m-person__copy {
			//padding: 70px 70px 70px 0;
			text-align: left;
			justify-content: center;
			margin: 0 40px 0 0;
		} 

		margin-bottom: 100px;

	}

	.m-person:not(.m-person--featured) {
		.m-person__title {
			margin-bottom: 0.75em;
		}
		.m-person__image {
			top: -60px;
		}	
	}

}

@include mq(mobile, desktop) {
	.m-person--featured {
		margin-bottom: 100px;
		margin-top: 100px;
	}
	.m-person__image {
		top: -60px;	
	}
}



.m-person__image {
	width: 100%;
	height: 100%;
	grid-area: person-image;
	position: relative;
	img {
		max-width: 119px;
		max-height: 119px;
		border-radius: 215px;
	}
}

.m-person__copy {
	grid-area: person-copy;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: -40px 40px 40px 40px;
	padding: 50px 0;
}

.m-person__title {
	order: 2;
}

.m-person__role {
	order: 1;
}

.m-person__bio {
	order: 3;
	line-height: 3rem;
}

.m-person__copy > a {
	order: 5;
}

