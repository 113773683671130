/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Crimson+Text&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * Globals
 */
/**
 * Colours
 */
/**
 * Sass MQ configuration
 */
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }

.glide * {
  box-sizing: inherit; }

.glide__track {
  overflow: hidden; }

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform; }

.glide__slides--dragging {
  user-select: none; }

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent; }

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none; }

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none; }

.glide--rtl {
  direction: rtl; }

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1; }

.glide__arrow:focus {
  outline: none; }

.glide__arrow:hover {
  border-color: white; }

.glide__arrow--left {
  left: 2em; }

.glide__arrow--right {
  right: 2em; }

.glide__arrow--disabled {
  opacity: 0.33; }

.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%); }

.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em; }

.glide__bullet:focus {
  outline: none; }

.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5); }

.glide__bullet--active {
  background-color: white; }

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  font-family: "Bebas"; }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  left: 0;
  /*
      /*rtl:end:ignore*/ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  right: 0;
  /*
      /*rtl:end:ignore*/ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill .1s;
  transition: fill .1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: .5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 .5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 .5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #959ea9; }

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7; }

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee; }

.flatpickr-input {
  background: url(../img/icons/calendar-header.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html {
    /* messy, horrible IE 10&11 hacks live here */ }
    html .m-nav--header > li > ul {
      transform: translate(-50%, 0); }
    html .l-index {
      display: flex;
      margin-left: -20px;
      flex-direction: row;
      flex-wrap: wrap; }
    html .l-index--people > div {
      margin-bottom: 100px; }
    html .l-index > div {
      flex: 1 1 auto;
      margin-left: 20px;
      width: calc(100% / 3 - 40px);
      margin-top: 50px; }
    html .m-person--featured .m-person__copy {
      padding-top: 60px; }
    html .m-person__copy {
      display: block; }
    html .m-form {
      display: flex;
      flex-direction: column; }
      html .m-form > * {
        flex: 1 1 auto;
        margin-bottom: 30px; }
    html .m-slide__image {
      max-width: 500px; }
    html .m-card {
      position: relative; }
    html .m-card-group .m-card {
      padding: 15px; }
    html .m-card--overlay .m-card__copy {
      position: absolute;
      left: 20px;
      bottom: 20px; }
    html .m-card__copy {
      max-width: 500px; }
    html .m-slide {
      display: flex;
      flex-direction: row; }
    html .m-slide__image {
      padding-right: 25px; }
    html .m-slide__copy {
      max-width: 50%; }
    html .m-form__submit-panel {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    html .m-slider__controls {
      grid-area: controls;
      max-width: 475px;
      margin-left: 0px; }
    html .m-slider {
      overflow: hidden; }
    html .t-image-pair {
      max-height: 220px; } }

/**
 * "Big" layout changes go in here, e.g. the overall grid layout structure and
 * any generic grid/layout classes intended to be reused throughout
 */
.l-container {
  display: grid;
  overflow-x: hidden;
  grid-template-columns: minmax(30px, 1fr) minmax(0px, 1440px) minmax(30px, 1fr);
  grid-template-rows: 100px 700px 68px auto auto;
  grid-template-areas: "header header header" "hero hero hero" ". search ." ". content ." "footer footer footer"; }
  @media (max-width: 46.24em) {
    .l-container {
      grid-template-rows: 100px 215px auto auto auto; } }
  @media (min-width: 46.25em) and (max-width: 61.24em) {
    .l-container {
      grid-template-rows: 100px 215px auto auto auto; } }

@media (min-width: 20em) and (max-width: 61.24em) {
  .l-container--sub {
    grid-template-rows: 100px 215px auto auto auto; } }

@media (min-width: 61.25em) {
  .l-container--sub {
    grid-template-rows: 100px 370px 68px auto auto; } }

.l-header {
  grid-area: header;
  background: white;
  z-index: 350; }

.l-header__inner {
  max-width: 1240px;
  margin: 0 auto;
  height: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (min-width: 61.25em) {
    .l-header__inner {
      align-items: stretch; } }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .l-header__inner {
      align-items: center; } }

@media (min-width: 20em) and (max-width: 61.24em) {
  .l-header__nav {
    position: absolute;
    z-index: 100;
    width: 100%;
    left: 0px;
    top: 100px;
    box-sizing: border-box; } }

.l-header-nav__detail {
  display: flex; }
  .l-header-nav__detail > * {
    margin-bottom: 20px; }

.l-logo {
  display: flex;
  flex-direction: row;
  align-items: center; }

.l-hero {
  grid-area: hero;
  background: #999; }

.l-search {
  grid-area: search;
  position: relative;
  top: -34px;
  padding-bottom: -34px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 61.24em) {
    .l-search {
      top: auto;
      padding-bottom: auto;
      flex-direction: column; } }

.l-search--fixed {
  position: fixed;
  top: 20px;
  min-width: 980px;
  max-width: 1215px;
  z-index: 500; }

.l-content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  align-items: center;
  /*display: grid;
	grid-template-columns: 100px 200px 1fr 200px 100px;

	// Content specific layout columns
	.l-column--full {
		grid-column-start: 1;
		grid-column-end: 6;
	}

	.l-column--medium {
		grid-column-start: 2;
		grid-column-end: 4;
	}

	.l-column--small {
		grid-column-start: 3;
		grid-column-end: 4;
	}*/ }

.l-footer {
  grid-area: footer; }

.l-footer__content {
  width: calc(100% - 30px);
  max-width: 1080px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 310px 435px minmax(1fr, 595px) minmax(1fr, 100px);
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 20px;
  order: 5; }
  @media (min-width: 46.25em) {
    .l-footer__content {
      grid-row-gap: 64px;
      grid-template-areas: "address contact nav nav" "logo logo subnav social"; } }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .l-footer__content {
      grid-row-gap: 18px;
      margin: 0 32px;
      grid-template-areas: "logo" "address" "contact" "nav" "social" "subnav"; } }

.l-footer__address {
  grid-area: address; }

.l-footer__contact {
  grid-area: contact; }

.l-footer__nav {
  grid-area: nav; }

.l-footer__logo {
  grid-area: logo; }

.l-footer__subnav {
  grid-area: subnav; }

.l-footer__social {
  grid-area: social; }

/**
 * Generic layouts
 */
.l-related {
  display: grid;
  grid-gap: 24px; }
  @media (min-width: 61.25em) {
    .l-related {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "r1 r2 r3"; }
      .l-related > *:nth-child(1) {
        grid-area: r1; }
      .l-related > *:nth-child(2) {
        grid-area: r2; }
      .l-related > *:nth-child(3) {
        grid-area: r3; } }

.l-index {
  display: grid;
  /*@include mq(tablet, , tablet) {
		grid-template-columns: repeat(2, 1fr);
	}*/
  grid-gap: 24px; }
  @media (min-width: 46.25em) {
    .l-index {
      grid-template-columns: repeat(3, 33.33%); } }
  @media (min-width: 46.25em) and (max-width: 61.24em) {
    .l-index {
      grid-template-columns: repeat(2, 50%); } }

.l-index--people {
  grid-column-gap: 36px;
  grid-row-gap: 100px; }

.l-grid {
  display: grid; }

.l-grid--5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 36px;
  /*@include mq(mobile) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mq($from: mobileLandscape, $until: tablet) {
		grid-template-columns: repeat(3, 1fr);
	}*/ }

/**
  *
  * Smaller adjustments go in here, e.g. adding 10px of padding to a specific element
  * on the homepage, or smaller alterations to how a module appears
  * 
  */
/**
  * Theme
  */
html {
  font-size: 62.5%;
  -webkit-font-smoothing: subpixel-antialiased; }

body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: "Crimson Text"; }

body.blackout .t-content:before {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 250;
  top: 0px;
  left: 0px;
  content: "";
  background: rgba(0, 0, 0, 0.5); }

address, li {
  line-height: 2.7rem; }

a {
  color: #0057AD;
  text-decoration: none; }

.l-content p, .l-content ul {
  margin-bottom: 1em;
  line-height: 3rem;
  color: #272727; }

.l-content ul li:before {
  content: "- "; }

img {
  width: 100%; }

/**
 * Inputs
 */
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

/**
 * Global template changes
 */
.t-hr {
  width: 129px;
  border: none;
  height: 1px;
  background: #0057AD;
  margin: 20px 0; }

.t-hr--light {
  background: white; }

.t-hr--full {
  width: 100%; }

.t-hr--grey {
  background: #D5D5D5; }

.t-list {
  display: flex;
  flex-direction: row; }
  .t-list li:not(:first-child) {
    margin-left: 28px; }

.t-logo--header .t-tagline {
  margin-left: 40px; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .t-logo--header .t-tagline {
      display: none; } }

@media (min-width: 46.25em) {
  .t-container--sub .m-hero__content {
    top: 0px; } }

/**
 * General layout adjustments
 */
.t-cta-after {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px; }
  .t-cta-after > * {
    position: relative;
    top: 10%; }

.t-header__nav {
  background: white;
  padding: 0 40px;
  z-index: 500; }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .t-header__nav {
      border-bottom: 2px solid #E5E5E5; } }

.t-header__blackout {
  height: calc(100vh - 100px);
  top: 100px;
  width: 100vw;
  display: none;
  position: fixed;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200; }

.t-header__burger {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative; }
  @media (min-width: 61.25em) {
    .t-header__burger {
      display: none; } }
  .t-header__burger svg {
    width: 100%;
    height: 100%; }

#headertoggle {
  display: none; }

#headertoggle:not(:checked) ~ label svg.on {
  display: none; }

#headertoggle:not(:checked) ~ label svg.off {
  display: block; }

#headertoggle:checked ~ label svg.on {
  display: block; }

#headertoggle:checked ~ label svg.off {
  display: none; }

@media (min-width: 61.25em) {
  .t-container--sub .t-content--with-search > .t-intro:first-child {
    margin-top: 30px; } }

.t-block {
  max-width: 1080px;
  width: 100%;
  position: relative; }
  @media (min-width: 61.25em) {
    .t-block {
      margin: 50px 0 0 0; } }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .t-block {
      margin: 0 0 25px 0; } }
  .t-block:last-child {
    padding-bottom: 100px; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .t-block:first-child {
      padding-top: 40px; } }

/*.t-container--sub .t-block:first-child {
	margin-top: 0px;
}*/
.t-block--filled {
  background: #F8F8F8;
  padding: 40px 0; }
  .t-block--filled:before, .t-block--filled:after {
    content: "";
    background: #F8F8F8;
    height: 100%;
    top: -1px; }
  .t-block--filled:before {
    position: absolute;
    left: calc(0px - 50vw + 50%);
    width: calc(50vw - 50%); }
  .t-block--filled:after {
    position: absolute;
    left: 100%;
    width: calc(50vw - 50%); }

.t-block--wide {
  max-width: 1170px; }

.t-block--full {
  max-width: 9999px; }

.t-block__heading {
  text-align: center;
  margin-bottom: 2em; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .t-block__heading {
      margin-bottom: 1em; } }

.t-logo {
  /*display: grid;
	grid-template-areas: "logo-image" "logo-text";
	grid-template-rows: 50px 18px;
	grid-row-gap: 5px;
	text-align: center;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .t-logo img {
    width: auto; }

.t-hero__contact-methods {
  text-transform: none; }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .t-hero__contact-methods {
      display: none; } }
  @media (min-width: 46.25em) and (max-width: 61.24em) {
    .t-hero__contact-methods ul {
      flex-direction: column; }
      .t-hero__contact-methods ul li {
        margin-bottom: 0.25em; } }

.t-intro {
  max-width: 640px;
  font-size: 2.4rem; }
  @media (min-width: 61.25em) {
    .t-intro {
      min-width: 640px; } }
  .t-intro p {
    font-size: inherit !important; }

.t-intro ~ .t-copy-column {
  margin-top: 0px; }

.t-container--sub:not(.t-container--has-parent) .t-intro:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #EAEAEA;
  margin: 20px 0 0 0; }

.t-slider--home-page {
  max-width: 100%; }
  .t-slider--home-page .m-slide {
    background: transparent; }

.t-card-group--home-page {
  margin-top: 0px; }
  @media (min-width: 61.25em) {
    .t-card-group--home-page, .t-card-group--home-page:before, .t-card-group--home-page:after {
      border-top: 1px solid #D0D0D0; } }

.t-copy-column {
  max-width: 600px; }
  .t-copy-column figure {
    max-width: 50%;
    float: left; }

.t-copy-column ~ .t-image-pair {
  max-width: 600px;
  margin: 40px 0;
  padding: 30px 0;
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA; }
  .t-copy-column ~ .t-image-pair .t-block__heading {
    display: none; }

.t-properties {
  max-width: 1092px; }

/**
 * Homepage template
 */
.t-container--home-page .m-slider__slide {
  background: transparent; }

.t-intro--home-page {
  margin: 110px 0 80px 0;
  text-align: center; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .t-intro--home-page {
      margin: 50px 0 65px 0; } }

.t-slider--homePage {
  padding-bottom: 100px; }

.t-card-group--homePage {
  max-width: 1020px; }

.t-card-group--subPage {
  max-width: 1120px; }

/**
 * Contact page
 */
@media (min-width: 61.25em) {
  .t-form--contact-page {
    min-width: 880px; } }

@media (min-width: 20em) and (max-width: 61.24em) {
  .t-form--contact-page {
    width: 100%;
    max-width: 880px; } }

/*.t-homePage__services {
	max-width: 1230px;
}

.t-homePage__properties {
	max-width: 900px;
	padding: 100px 0;
}*/
/**
 * Properties page
 */
.t-container--properties-page, .t-container--meet-our-team-page {
  background: #F8F8F8; }

/**
 * Team page
 */
@media (min-width: 20em) and (max-width: 46.24em) {
  .t-meet-our-team .m-people {
    margin-top: 100px; } }

/**
 * Footer template
 */
.t-footer {
  background: #0057AD; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .t-footer {
      padding: 50px 0px; } }
  @media (min-width: 46.25em) {
    .t-footer {
      padding: 85px 0px; } }
  .t-footer * {
    color: white; }
  .t-footer img {
    width: auto;
    display: block; }

.t-footer__logo img {
  padding-bottom: 5px; }

.t-footer__nav ul, .t-footer__subnav ul {
  display: flex; }
  @media (min-width: 46.25em) {
    .t-footer__nav ul, .t-footer__subnav ul {
      justify-content: space-between;
      flex-direction: row; } }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .t-footer__nav ul, .t-footer__subnav ul {
      flex-direction: column; } }

.t-footer__social .t-ul {
  flex-direction: row; }
  @media (min-width: 46.25em) {
    .t-footer__social .t-ul {
      justify-content: flex-end; } }
  .t-footer__social .t-ul li:not(:last-child) {
    margin-right: 14px; }

.t-footer__social .t-icon:before {
  width: 30px;
  height: 30px; }

.t-ul {
  display: flex;
  flex-direction: column; }

.t-ul--horiz {
  display: flex;
  flex-direction: row; }
  .t-ul--horiz > li:not(:last-child) {
    margin-right: 1em; }

.t-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*justify-content: center;
	*/ }
  .t-icon:before {
    content: "";
    position: relative;
    display: block;
    width: 14px;
    height: 14px;
    top: 2px;
    background-color: transparent;
    background-size: contain;
    background-position: center;
    margin-right: 0.4em; }

.t-icon--linkedin:before {
  background-image: url(../img/icons/linked-in.svg); }

.t-icon--phone:before {
  background-image: url(../img/icons/phone.svg); }

.t-icon--email:before {
  background-image: url(../img/icons/email-header.svg); }

/**
 * Typography things
 */
@font-face {
  font-family: 'Bebas';
  src: url("../fonts/Bebas-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Bebas-Regular.otf") format("opentype"), url("../fonts/Bebas-Regular.woff") format("woff"), url("../fonts/Bebas-Regular.ttf") format("truetype"), url("../fonts/Bebas-Regular.svg#Bebas-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

body {
  -webkit-font-smoothing: antialiased; }

/**
 * Text colour overrides
 */
.text--dark-grey {
  color: #3C3C3C; }

.text--med-grey {
  color: #494949; }

.text--light-grey {
  color: #E5E5E5; }

.text--grey {
  color: #B7B7B7; }

.text--grey2 {
  color: #A0A0A0; }

/**
 * Text style overrides
 */
.text--italic {
  font-style: italic; }

.text--slider-large {
  color: #8F8F8F;
  font-size: 3.6rem;
  font-family: "Crimson Text";
  line-height: 4.5rem; }

.text--slider-small {
  color: #8F8F8F;
  font-family: "Crimson Text";
  font-size: 1.8rem;
  line-height: 2.5rem; }

.h--hero {
  font-size: 6.4rem;
  line-height: 77px;
  font-family: "Bebas"; }
  @media (max-width: 61.24em) {
    .h--hero {
      font-size: 24px;
      line-height: 34px; } }

h1, h2, h3, h4, h5, h6 {
  font-family: "Bebas"; }

.h--large {
  font-size: 3.6rem;
  line-height: 4.3rem;
  margin-bottom: 0.75em; }
  @media (max-width: 46.24em) {
    .h--large {
      font-size: 2.8rem;
      line-height: 3.4rem; } }

.h--large-serif {
  font-size: 3.6rem;
  line-height: 4.3rem;
  font-family: "Crimson Text"; }
  @media (max-width: 46.24em) {
    .h--large-serif {
      font-size: 2.8rem;
      line-height: 3.8rem; } }

.h--large-sub {
  font-size: 2rem;
  line-height: 2.7rem;
  margin-bottom: 0.2em;
  font-family: "Crimson Text";
  color: #8F8F8F; }

.h--med {
  font-size: 2.8rem;
  line-height: 3.4rem;
  margin-bottom: 0.6em; }
  @media (max-width: 46.24em) {
    .h--med {
      font-size: 2.8rem;
      line-height: 3.4rem; } }

.h--med-serif {
  font-size: 2.8rem;
  line-height: 3.0rem;
  margin-bottom: 0.6em;
  font-family: "Crimson Text"; }
  @media (max-width: 46.24em) {
    .h--med-serif {
      font-size: 2.8rem;
      line-height: 3.4rem; } }

.h--med-sub {
  font-size: 2rem;
  line-height: 2.7rem;
  margin-bottom: 0.2em;
  font-family: "Crimson Text";
  color: #B7B7B7; }

.h--small-serif {
  font-size: 2.6rem;
  line-height: 3rem;
  font-family: "Crimson Text";
  color: #313131; }

.h--tiny-serif {
  font-size: 2.2rem;
  line-height: 3rem;
  font-family: "Crimson Text";
  color: #313131; }

.text--center {
  text-align: center; }

.text--large {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-family: "Crimson Text"; }

.text--regular {
  font-size: 1.8rem;
  line-height: 30px;
  font-family: "Crimson Text"; }

.text--regular-condensed {
  font-size: 1.8rem;
  line-height: 28px;
  font-family: "Crimson Text"; }

.text--small-serif {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "Crimson Text"; }

.text--button {
  font-size: 1.8rem;
  line-height: 2.4rem; }

.text--nav {
  font-size: 2rem; }

.text--mob-nav {
  font-size: 2.8rem;
  line-height: 3.8rem; }

.text--mob-subnav {
  font-size: 2.2rem;
  line-height: 3.0rem; }

.text--formatted h1, .text--formatted h2, .text--formatted h3 {
  font-family: "Crimson Text";
  font-size: 2.8rem;
  line-height: 3rem;
  margin-bottom: 0.75em; }

.u-mobile-toggle {
  display: none;
  width: 40px;
  height: 40px;
  background: #666; }

@media (min-width: 20em) and (max-width: 61.24em) {
  .u-mobile-toggle {
    display: block; }
  .u-mobile-toggle__target {
    display: none; }
  .u-mobile-toggle:checked ~ .u-mobile-toggle__target {
    display: block; }
  .u-mobile-toggle:checked ~ .u-mobile-toggle__target {
    display: block; } }

.u-hidden {
  display: none; }

@media (min-width: 20em) and (max-width: 29.99em) {
  .u-visible--mobile {
    display: block; } }

@media (min-width: 30em) and (max-width: 46.24em) {
  .u-visible--mobileLandscape {
    display: block; } }

@media (min-width: 46.25em) and (max-width: 61.24em) {
  .u-visible--tablet {
    display: block; } }

.m-button-group {
  display: flex; }
  .m-button-group > .m-button:not(:last-child) {
    margin-right: 19px; }
  .m-button-group > .m-button:not(:first-child) {
    background: white;
    color: #0057AD;
    border: 1px solid #0057AD; }
    .m-button-group > .m-button:not(:first-child):hover {
      background: #0057AD;
      color: white;
      border-color: #0057AD; }

.m-button-group--double .m-button {
  width: 50%; }

.m-button {
  height: 45px;
  appearance: none;
  background: #0057AD;
  border: 1px solid #0057AD;
  border-radius: 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Crimson Text";
  font-size: 18px;
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out, border 0.25s ease-in-out; }
  @media (min-width: 46.25em) {
    .m-button {
      width: 176px; } }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .m-button {
      width: 100%; } }
  .m-button:hover {
    background: white;
    color: #0057AD;
    border-color: #0057AD; }

.m-button--fill {
  width: 100%;
  height: 100%;
  min-height: 68px; }

.m-button--full-width {
  width: 100%; }

.m-button--blue {
  color: white;
  background: #0057AD; }

.m-card-group {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px; }

@media (min-width: 61.25em) {
  .m-card-group {
    grid-template-columns: 1fr 1fr; }
  .m-card-group--2 {
    grid-template-areas: "left right"; }
    .m-card-group--2 .card-1 {
      grid-area: left; }
    .m-card-group--2 .card-2 {
      grid-area: right; }
    .m-card-group--2 .m-card__copy {
      justify-content: flex-start; }
  .m-card-group--3 {
    grid-template-areas: "left top-right" "left bottom-right"; }
    .m-card-group--3 .card-1 {
      grid-area: left; }
    .m-card-group--3 .card-2 {
      grid-area: top-right; }
    .m-card-group--3 .card-3 {
      grid-area: bottom-right; }
  .m-card-group--4 {
    grid-template-areas: "top-left top-right" "top-left bottom-right" "bottom-left bottom-right";
    grid-template-rows: auto 150px auto; }
    .m-card-group--4 .card-1 {
      grid-area: top-left; }
    .m-card-group--4 .card-2 {
      grid-area: top-right; }
    .m-card-group--4 .card-3 {
      grid-area: bottom-left; }
    .m-card-group--4 .card-4 {
      grid-area: bottom-right; } }

.m-card {
  display: grid;
  grid-template-areas: "card-image"	 "card-content";
  grid-template-columns: 1fr;
  grid-template-rows: 312px auto;
  grid-row-gap: 20px;
  background: white; }

/**
 * Only permit the horizontal card configuration
 * on desktop
 */
@media (min-width: 46.25em) {
  .m-card--horizontal {
    grid-template-areas: "card-image card-content";
    grid-template-rows: minmax(278px, 100%);
    grid-template-columns: 233px auto;
    grid-column-gap: 20px; } }

/*.m-card--vertical {
	.m-card__copy {
		padding
	}	
}

.m-card--horizontal {
	.m-card__copy {
		@include mq(mobile, desktop) {
			padding: 0 22px 22px 22px;
		}
	}	
}*/
.m-card--overlay {
  grid-template-areas: "card-image card-content";
  grid-template-rows: minmax(278px, 100%);
  grid-template-columns: 1fr;
  grid-column-gap: 0px; }
  .m-card--overlay .m-card__copy {
    grid-area: card-image;
    padding: 22px;
    justify-content: flex-end; }
    .m-card--overlay .m-card__copy * {
      color: white; }
  .m-card--overlay .m-button {
    border-color: white;
    background: transparent;
    color: white; }
    .m-card--overlay .m-button:hover {
      background: white;
      color: #272727; }

.m-card--vertical__gradient, .m-card--horizontal__gradient {
  display: none; }

.m-card--overlay__gradient {
  grid-area: card-image;
  width: 100%;
  height: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+99&0+0,1+87 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 87%, black 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 87%, black 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 87%, black 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
  /* IE6-9 */
  z-index: 2;
  opacity: 0.5;
  transition: opacity 1s ease-out; }

.m-card:hover .m-card--overlay__gradient {
  opacity: 1; }

.m-card__image {
  grid-area: card-image;
  width: 100%;
  z-index: 1; }
  .m-card__image > img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.m-card__copy {
  z-index: 3;
  grid-area: card-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22px 22px 22px 0; }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .m-card__copy {
      padding-left: 22px;
      padding-top: 0px; } }

.m-card--vertical .m-card__copy {
  padding-top: 0px; }

.m-card--vertical .m-card__copy,
.m-card--overlay .m-card__copy {
  padding-left: 22px; }

.m-card__content {
  box-sizing: border-box;
  /* 2 */
  max-width: 100%;
  /* 1 */ }

.l-card__title {
  order: 2; }

.l-card__subtitle {
  order: 1; }

.l-card__content {
  order: 3; }

.m-case-study {
  display: grid; }
  @media (max-width: 46.24em) {
    .m-case-study {
      grid-template-areas: "cs-image" "cs-content";
      grid-row-gap: 20px; } }
  @media (min-width: 46.25em) {
    .m-case-study {
      grid-template-areas: "cs-image cs-content";
      grid-template-columns: 50% 50%;
      grid-column-gap: 40px; } }

.m-case-study__image {
  grid-area: cs-image; }

.m-case-study__content {
  grid-area: cs-content;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.m-entry-card {
  background: white;
  display: flex;
  flex-direction: column; }

.m-entry-card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.m-entry-card__content {
  max-width: 100%;
  padding: 32px 39px;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  background: white; }
  @media (min-width: 61.25em) {
    .m-entry-card__content {
      align-items: center;
      text-align: center; } }

select {
  appearance: none;
  position: relative;
  width: 100%;
  background: url(../img/icons/select-caret.svg);
  background-position: 100% center;
  background-repeat: no-repeat;
  background-size: 15px 15px; }

input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: #0057AD !important; }

.m-form {
  display: grid;
  grid-gap: 30px; }
  .m-form .errors li {
    color: #EF4646; }
  .m-form label {
    display: flex;
    flex-direction: column;
    color: #0057AD;
    font-weight: bold; }
  .m-form input:not([type='submit']), .m-form select {
    margin-top: 9px;
    line-height: 69px;
    height: 69px;
    appearance: none;
    border: 1px solid #D1D1D1;
    border-radius: 0px;
    font-family: "Crimson Text";
    font-size: 18px;
    color: #868686;
    border: 1px solid #D1D1D1;
    text-indent: 23px; }
  .m-form textarea {
    margin-top: 9px;
    font-family: "Crimson Text";
    appearance: none;
    font-size: 18px;
    color: #868686;
    padding: 23px;
    border-radius: 0px;
    border: 1.2px solid #D1D1D1; }

.m-form__input {
  width: 100%; }

.m-form__submit-panel {
  position: relative; }
  @media (min-width: 61.25em) {
    .m-form__submit-panel {
      display: grid;
      grid-template-columns: repeat(3, calc(100%/3));
      /*justify-content: space-between;
		align-items: center;
		.note {

			margin: 0;
		}*/ }
      .m-form__submit-panel nth-child(2) {
        grid-column: 2; }
      .m-form__submit-panel nth-child(3) {
        grid-column: 3;
        text-align: right; } }

@media (min-width: 46.25em) {
  .m-form__input--full {
    grid-column: 1 / 3; }
  .m-form__submit-panel {
    grid-column: 1 / 3; } }

.m-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center; }

.m-hero__content {
  background: rgba(0, 87, 173, 0.7);
  font-family: "Crimson Text";
  text-transform: uppercase;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; }
  .m-hero__content * {
    color: white; }
  @media (min-width: 61.25em) {
    .m-hero__content {
      padding: 40px 60px;
      top: -10%; } }
  @media (max-width: 61.24em) {
    .m-hero__content {
      max-width: 300px;
      min-height: 96px;
      min-width: 268px; }
      .m-hero__content .l-hero__hr, .m-hero__content .l-hero__list {
        display: none; } }
  @media (min-width: 46.25em) and (max-width: 61.24em) {
    .m-hero__content {
      max-width: 600px; } }

.l-hero__heading {
  margin: 0; }

.m-image-pair {
  display: grid;
  grid-gap: 16px; }
  @media (min-width: 46.25em) {
    .m-image-pair {
      grid-template-areas: "image1 image2";
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .m-image-pair {
      grid-template-areas: "image1" "image2";
      grid-template-rows: 1fr 1fr; } }

.m-image-pair > :nth-child(1) {
  grid-area: image1; }

.m-image-pair > :nth-child(2) {
  grid-area: image2; }

/**
 * Header template
 */
.m-nav {
  display: flex;
  flex-direction: row;
  height: 100%; }

@media (min-width: 20em) and (max-width: 61.24em) {
  .m-nav__subtoggle {
    position: relative; }
  .m-nav__subtoggle:checked ~ .m-nav__subtoggle-label {
    background-image: url(../img/icons/Upward.svg); }
  .m-nav__subtoggle-label {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    top: 8px;
    background: url(../img/icons/Downward.svg) no-repeat;
    background-size: contain;
    background-position: center; } }

.m-nav--header__burger {
  width: 50px;
  height: 50px;
  background: #000;
  border: 0; }

.m-nav--header__burger:checked ~ .m-nav--header {
  display: block; }

.m-nav--header {
  height: 100%;
  position: relative; }
  .m-nav--header a {
    color: #3C3C3C; }
  @media (max-width: 61.24em) {
    .m-nav--header {
      height: auto;
      background: white;
      z-index: 100;
      margin-top: 30px;
      display: flex;
      flex-direction: column; }
      .m-nav--header a {
        color: inherit; }
      .m-nav--header li {
        margin: 0.45em 0;
        position: relative; }
      .m-nav--header .m-nav__subtoggle {
        opacity: 0;
        pointer-events: none; }
      .m-nav--header > li {
        position: relative; }
        .m-nav--header > li > a {
          font-size: 2.8rem;
          line-height: 3.8rem; }
        .m-nav--header > li .u-mobile-toggle {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 40px;
          height: 40px; }
        .m-nav--header > li > ul {
          margin-left: 0px;
          padding-left: 0px; }
        .m-nav--header > li li:hover {
          background: #F8F8F8; }
        .m-nav--header > li li > a {
          font-size: 2.2rem;
          line-height: 3.2rem;
          color: #737373;
          text-indent: 2rem;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .m-nav--header > li li > a:before {
            content: "";
            display: block;
            width: 10px;
            position: relative;
            top: 1px;
            height: 2px;
            margin-right: 11px;
            background: #C4C4C4; } }
  @media (min-width: 61.25em) {
    .m-nav--header > li {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center; }
      .m-nav--header > li > a {
        height: 100%;
        padding: 0 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3C3C3C; }
      .m-nav--header > li:after, .m-nav--header > li.m-nav__li--active:after {
        content: "";
        display: block;
        height: 4px;
        width: 10%;
        opacity: 0;
        left: 45%;
        transition: all 0.25s ease-in-out;
        background: #0057AD;
        position: absolute;
        bottom: 2px; }
      .m-nav--header > li.m-nav__li--active:after {
        width: 80%;
        left: 10%;
        opacity: 1; }
      .m-nav--header > li:hover:after {
        width: 80%;
        left: 10%;
        opacity: 1; }
      .m-nav--header > li:hover > ul {
        opacity: 1;
        pointer-events: all; }
      .m-nav--header > li > ul {
        transition: opacity 0.1s ease-in-out;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        box-shadow: 0px 7px 10px -2px rgba(0, 0, 0, 0.75);
        z-index: 100;
        top: 100%;
        background: white; }
        .m-nav--header > li > ul li {
          min-height: 44px;
          border-bottom: 1px solid #B7B7B7;
          transition: background 0.25s ease-in-out;
          /*display: flex;
					flex-direction: column;
					justify-content: center;*/ }
          .m-nav--header > li > ul li:hover {
            background: #F8F8F8; }
          .m-nav--header > li > ul li > ul {
            border-top: 1px solid #B7B7B7; }
            .m-nav--header > li > ul li > ul li:last-child {
              border-bottom: none; }
            .m-nav--header > li > ul li > ul a:before {
              content: "- ";
              margin-right: 10px; }
          .m-nav--header > li > ul li > a {
            white-space: nowrap;
            display: flex;
            align-items: center;
            height: 44px;
            width: 100%;
            padding: 0 1em;
            /*display: block;
						*/ } }

.m-people {
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(min-content, 100px));
  grid-column-gap: 36px;
  grid-row-gap: 100px; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .m-people {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr; } }

.m-person {
  display: grid;
  grid-template-areas: "person-image" "person-copy";
  border: 1px solid #B7B7B7;
  background: white;
  text-align: center; }

.readmore-people {
  height: 120px;
  overflow: hidden; }

.readmore-people.visible {
  overflow: visible;
  height: auto; }

@media (min-width: 61.25em) {
  .m-person--featured {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-template-areas: "person-image person-copy";
    margin-bottom: 100px; }
    .m-person--featured .m-person__image img {
      max-width: 210px;
      max-height: 210px;
      margin: 50px;
      border-radius: 215px;
      -webkit-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5); }
    .m-person--featured .m-person__copy {
      text-align: left;
      justify-content: center;
      margin: 0 40px 0 0; }
  .m-person:not(.m-person--featured) .m-person__title {
    margin-bottom: 0.75em; }
  .m-person:not(.m-person--featured) .m-person__image {
    top: -60px; } }

@media (min-width: 20em) and (max-width: 61.24em) {
  .m-person--featured {
    margin-bottom: 100px;
    margin-top: 100px; }
  .m-person__image {
    top: -60px; } }

.m-person__image {
  width: 100%;
  height: 100%;
  grid-area: person-image;
  position: relative; }
  .m-person__image img {
    max-width: 119px;
    max-height: 119px;
    border-radius: 215px; }

.m-person__copy {
  grid-area: person-copy;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: -40px 40px 40px 40px;
  padding: 50px 0; }

.m-person__title {
  order: 2; }

.m-person__role {
  order: 1; }

.m-person__bio {
  order: 3;
  line-height: 3rem; }

.m-person__copy > a {
  order: 5; }

.m-properties__intro {
  margin: 0 auto;
  text-align: center;
  max-width: 500px;
  margin-top: -20px; }

.m-properties__slides {
  padding-top: 10px; }

.m-properties__slides .glide__slides {
  align-items: center; }

.m-properties__slide {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px; }
  .m-properties__slide img {
    object-fit: contain;
    object-position: center center;
    height: 100%;
    height: 92px; }

.m-properties__bullets {
  position: absolute;
  bottom: -50px; }

.m-properties__bullet, .glide__bullet:hover, .glide__bullet:active, .glide__bullet:focus {
  background-color: #909090;
  border: none;
  width: 12px;
  height: 12px; }

.m-related-entry {
  background: white; }

.m-search {
  display: grid;
  grid-gap: 10px;
  /*@include mq($from: tablet, $until: desktop) {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 10px;
	}*/ }
  .m-search input, .m-search select {
    line-height: 30px;
    border: 0;
    appearance: none;
    font-family: "Bebas";
    color: #3C3C3C;
    font-size: 18px;
    min-height: 30px; }
  @media (max-width: 61.24em) {
    .m-search {
      width: 100%;
      margin-top: 30px;
      grid-template-rows: repeat(5, 68px);
      grid-template-areas: "hotel" "date-from" "date-to" "promo" "search"; } }
  .m-search button {
    font-size: 2rem;
    line-height: 2.7rem;
    font-family: "Crimson Text";
    cursor: pointer; }
  @media (min-width: 61.25em) {
    .m-search {
      grid-template-columns: repeat(5, 1fr);
      grid-template-areas: "hotel date-from date-to promo search";
      background: white;
      box-shadow: 0px 7px 10px -2px rgba(0, 0, 0, 0.75);
      grid-column-gap: 10px; } }
  .m-search > .m-search__label[for=hotel] {
    grid-area: hotel; }
  .m-search > .m-search__label[for=from] {
    grid-area: date-from; }
  .m-search > .m-search__label[for=to] {
    grid-area: date-to; }
  .m-search > .m-search__label[for=promo] {
    grid-area: promo; }
  .m-search > .m-search__submit {
    grid-area: search; }

.m-search__label {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 1.8;
  padding: 10px 20px;
  background: white;
  color: #8F8F8F; }
  @media (max-width: 61.24em) {
    .m-search__label {
      border: 1px solid #B7B7B7;
      padding: 0 14px;
      justify-content: center; } }

.m-search__label--promo {
  /*@include mq($from: tablet, $until: desktop) {
		grid-column-start: 1;
		grid-column-end: 3; 
	}*/ }

/*.m-search__input--select {
	width: 97px;
}

.m-search__input {
	width: 70px;
	height: 30px;
}*/
.m-slider {
  display: grid;
  grid-template-areas: "slider" "controls"; }

.m-slider__slides {
  grid-area: slider; }

.m-slider__controls {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 48px;
  z-index: 100;
  pointer-events: none; }
  @media (min-width: 81.25em) {
    .m-slider__controls {
      max-width: calc(50% - 25px);
      margin-left: calc(50% + 25px);
      grid-area: slider; } }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .m-slider__controls {
      grid-area: controls; } }

.m-slider__next {
  cursor: pointer;
  width: 55px;
  height: 55px;
  background: #0057AD;
  border: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto; }
  .m-slider__next:after {
    display: block;
    content: ">";
    color: white;
    background: #0057AD; }

.m-slide {
  display: grid;
  background: #F8F8F8; }
  @media (min-width: 30em) {
    .m-slide {
      grid-template-areas: "image copy";
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 50px; } }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .m-slide {
      grid-template-areas: "image" "copy";
      grid-row-gap: 30px; } }

.m-slide__image {
  grid-area: image; }
  .m-slide__image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.m-slide__copy {
  grid-area: copy;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100; }
  @media (min-width: 61.25em) {
    .m-slide__copy {
      max-width: 75%; } }

.m-text-image-pair {
  display: grid; }
  @media (min-width: 46.25em) {
    .m-text-image-pair {
      grid-template-columns: 1fr 40px 1fr;
      grid-template-areas: "ipLeft gap ipRight";
      grid-column-gap: 40px; } }
  @media (max-width: 46.24em) {
    .m-text-image-pair {
      grid-template-areas: "ipLeft" "ipRight";
      grid-row-gap: 40px; } }

@media (min-width: 46.25em) {
  .m-text-image-pair--textThenImage {
    grid-template-areas: "ipRight gap ipLeft";
    grid-column-gap: 40px; } }

@media (max-width: 46.24em) {
  .m-text-image-pair--textThenImage {
    grid-template-areas: "ipRight" "ipLeft";
    grid-row-gap: 40px; } }

.m-text-image-pair__image {
  grid-area: ipLeft; }

.m-text-image-pair__copy {
  grid-area: ipRight;
  display: flex;
  flex-direction: column;
  justify-content: center; }
