.m-search {


	display: grid;
	grid-gap: 10px;
	

	// Form styling specific to the search box, for all other
	// form styling see form.scss
	input, select {
		line-height: 30px;
		border: 0;
		appearance: none;
		font-family: $sans;
		color: #3C3C3C;
		font-size: 18px;
		min-height: 30px;
	}

	@include mq($until: desktop) {
		width: 100%;
		margin-top: 30px;
		grid-template-rows: repeat(5, 68px);
		grid-template-areas: "hotel" "date-from" "date-to" "promo" "search";
	}

	button {
		font-size: 2rem;
		line-height: 2.7rem;
		font-family: $serif;
		cursor: pointer;
	}

	/*@include mq($from: tablet, $until: desktop) {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 10px;
	}*/

	@include mq($from: desktop) {
		grid-template-columns: repeat(5, 1fr);
		grid-template-areas: "hotel date-from date-to promo search";
		background: white;
		box-shadow: 0px 7px 10px -2px rgba(0,0,0,0.75);
		grid-column-gap: 10px;
	}	

	> .m-search__label[for=hotel] {
		grid-area: hotel;
	}

	> .m-search__label[for=from] {
		grid-area: date-from;
	}

	> .m-search__label[for=to] {
		grid-area: date-to;
	}

	> .m-search__label[for=promo] {
		grid-area: promo;
	}

	> .m-search__submit {
		grid-area: search;
	}

}

.m-search__label {

	display: flex;
	flex-direction: column;
	font-size: 1.4rem;
	line-height: 1.8;
	padding: 10px 20px;
	background: white;
	color: #8F8F8F;

	@include mq($until: desktop) {
		border: 1px solid $grey;
		//margin-bottom: 10px;
		//max-width: 9999px;		
		padding: 0 14px;
		justify-content: center;
		//height: 68px;
	}
}

.m-search__label--promo {
	/*@include mq($from: tablet, $until: desktop) {
		grid-column-start: 1;
		grid-column-end: 3; 
	}*/
}

/*.m-search__input--select {
	width: 97px;
}

.m-search__input {
	width: 70px;
	height: 30px;
}*/