

.m-card-group {
	display: grid; 
	grid-column-gap: 20px;
	grid-row-gap: 20px;
}

@include mq($from:desktop) {

	.m-card-group {
		grid-template-columns: 1fr 1fr;
	}
	
	.m-card-group--2 {
		grid-template-areas:
			"left right";
		.card-1 {
			grid-area: left;
		}
		.card-2 {
			grid-area: right;
		}
		.m-card__copy {
			justify-content: flex-start;
		}
	}

	.m-card-group--3 {
		grid-template-areas:
			"left top-right"
			"left bottom-right";
		.card-1 {
			grid-area: left;
		}
		.card-2 {
			grid-area: top-right;
		}
		.card-3 {
			grid-area: bottom-right;
		}
	}

	.m-card-group--4 {
		grid-template-areas:
			"top-left top-right"
			"top-left bottom-right"
			"bottom-left bottom-right";
		grid-template-rows: auto 150px auto;
		.card-1 {
			grid-area: top-left;
		}
		.card-2 {
			grid-area: top-right;
		}
		.card-3 {
			grid-area: bottom-left;
		}
		.card-4 {
			grid-area: bottom-right;
		}
	}
}

.m-card {
	display: grid;
	grid-template-areas:
		"card-image"	
		"card-content";
	grid-template-columns: 1fr;
	grid-template-rows:
		312px auto;
	grid-row-gap: 20px;	
	background: white;
}

/**
 * Only permit the horizontal card configuration
 * on desktop
 */
@include mq($from:tablet) {
	.m-card--horizontal {
		grid-template-areas:
			"card-image card-content";
		grid-template-rows: minmax(278px, 100%);
		grid-template-columns:
			233px auto;
		grid-column-gap: 20px;
	}
}

/*.m-card--vertical {
	.m-card__copy {
		padding
	}	
}

.m-card--horizontal {
	.m-card__copy {
		@include mq(mobile, desktop) {
			padding: 0 22px 22px 22px;
		}
	}	
}*/

.m-card--overlay {
	grid-template-areas:
		"card-image card-content";
	grid-template-rows: minmax(278px, 100%);
	grid-template-columns:
		1fr;
	grid-column-gap: 0px;

	// For the overlay, place the copy within the image cell
	.m-card__copy {
		grid-area: card-image;
		padding: 22px;
		justify-content: flex-end;
		* {
			color: white;
		}
	}

	.m-button {
		border-color: white;
		background: transparent;
		color: white;
		&:hover {
			background: white;
			color: $darkerGrey;
		}
	}

}

// Disable overlay view for tablet/portrait
@include mq($from: tablet, $until: tablet, $and: "(orientation: portrait)") {
	.m-card--overlay {
		
	}
}

.m-card--vertical__gradient,  .m-card--horizontal__gradient {
	display: none;
}

.m-card--overlay__gradient {
	grid-area: card-image;
	width: 100%;
	height: 100%;

	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+99&0+0,1+87 */
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 87%, rgba(0,0,0,1) 99%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 87%,rgba(0,0,0,1) 99%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 87%,rgba(0,0,0,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

	z-index: 2;

	opacity: 0.5;
	transition: opacity 1s ease-out;
}

.m-card:hover .m-card--overlay__gradient {
	opacity: 1;
}


.m-card__image {
	grid-area: card-image;
	width: 100%;
	z-index: 1;
	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;		
	}
}

.m-card__copy {
	z-index: 3;
	grid-area: card-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 22px 22px 22px 0;
	@include mq(mobile, desktop) {
		padding-left: 22px;
		padding-top: 0px;
	}
}

.m-card--vertical .m-card__copy {
	padding-top: 0px;
}

.m-card--vertical .m-card__copy,
.m-card--overlay .m-card__copy {
	padding-left: 22px;
}

.m-card__content {
	box-sizing: border-box; /* 2 */
	max-width: 100%; /* 1 */
}


// Display order of title/subtitle
.l-card__title {
	order: 2;
}

.l-card__subtitle {
	order: 1;
}

.l-card__content {
	order: 3;
}