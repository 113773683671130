

.m-slider {
	display: grid;
	grid-template-areas: "slider" "controls";
}

.m-slider__slides {
	grid-area: slider;
}

.m-slider__controls {
	@include mq(wide) {
		max-width: calc(50% - 25px);
		margin-left: calc(50% + 25px);
		grid-area: slider;
	}
	@include mq(mobile, wide) {
		grid-area: controls;
	}
	display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 48px;
    z-index: 100;
    pointer-events: none;
}

.m-slider__next {
	cursor: pointer;
	width: 55px;
	height: 55px;
	background: $blue;
	border: 0;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: auto;
	&:after {
		display: block;
		content: ">";
		color: white;
		background: $blue;
	}
}

.m-slide {

	display: grid;	
	background: #F8F8F8;

	@include mq(mobileLandscape) {
		grid-template-areas: "image copy";
		grid-template-columns: 1fr 1fr;	
		grid-column-gap: 50px;
	}

	@include mq(mobile, mobileLandscape) {
		grid-template-areas: "image" "copy";		
		grid-row-gap: 30px;
	}

	
	
}

.m-slide__image {
	grid-area: image;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.m-slide__copy {
	grid-area: copy;
	display: flex;
	flex-direction: column;
	justify-content: center; 
	z-index: 100;
	@include mq(desktop) {
		max-width: 75%;
	}
}
