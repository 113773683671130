
.m-button-group {
	display: flex;
	> .m-button:not(:last-child) {
		margin-right: 19px;
	}
	> .m-button:not(:first-child) {
		background: white;
		color: $blue;
		border: 1px solid $blue;
		&:hover {
			background: $blue;
			color: white;
			border-color: $blue;
		}
	}
}

.m-button-group--double {
	.m-button {
		width: 50%;
	}
}

.m-button {
	height: 45px;
	@include mq(tablet) {
		width: 176px;	
	}
	// make buttons span full width on mobile/landscape
	@include mq(mobile, tablet) {
		width: 100%;
	}
	appearance: none;
	background: $blue;
	border: 1px solid $blue;
	border-radius: 0px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: $serif;
	font-size: 18px;
	transition: color 0.1s ease-in-out,
				background 0.2s ease-in-out,
				border 0.25s ease-in-out;
	&:hover {
		background: white;
		color: $blue;
		border-color: $blue;
	}
}

.m-button--fill {
	width: 100%;
	height: 100%;
	min-height: 68px;
}

.m-button--full-width {
	width: 100%;
}

.m-button--blue {
	color: white;
	background: $blue;
}