.m-properties__slides {
	//display: flex;
}

.m-properties__intro {
	margin: 0 auto;
	text-align: center;
	max-width: 500px;
	margin-top: -20px;
}

.m-properties__slides {
	padding-top: 10px;
}

.m-properties__slides .glide__slides {
	align-items: center;
}

.m-properties__slide {
	width: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	padding: 10px;
	//border: 1px solid #D5D5D5;
	//padding: 10px;
	img {
		object-fit: contain;
		object-position: center center;
		height: 100%;
		height: 92px;
	}
}

.m-properties__bullets {
	position: absolute;
	bottom: -50px;
}

.m-properties__bullet, .glide__bullet:hover, .glide__bullet:active, .glide__bullet:focus {
	background-color: #909090;
	border: none;
	width: 12px;
	height: 12px;
}

.glide__bullet--active {
	//background-color: #0057AD	
}

