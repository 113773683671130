
/**
 * Globals
 */

$body-font: 62.5%;


$sans: "Bebas";
$serif: "Crimson Text";

/**
 * Colours
 */

$blue: #0057AD;
$lightGrey: #E5E5E5;
$grey: #B7B7B7;
$grey2: #A0A0A0;
$grey3: #737373;
$sliderGrey: #8F8F8F;
$medGrey: #494949;
$darkGrey: #3C3C3C;
$darkGrey2: #313131;
$darkerGrey: #272727;
$primary: $blue; 

/**
 * Sass MQ configuration
 */

// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
$mq-breakpoints: (
    mobile:  320px,
    tablet:  740px,
    desktop: 980px,
    wide:    1300px,

    // Tweakpoints
    // desktopAd: 810px,
    mobileLandscape: 480px
);

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width. For example: (mobile, tablet, desktop).
//$mq-show-breakpoints: (mobile, mobileLandscape, tablet, desktop, wide);