@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    
    html {
    	/* messy, horrible IE 10&11 hacks live here */
	
		.m-nav--header > li > ul {
			transform: translate(-50%, 0); 

		}

		.l-index {
			display: flex;
			margin-left: -20px;
			flex-direction: row;
			flex-wrap: wrap;
		}

		.l-index--people > div {
			margin-bottom: 100px;
		}

		.l-index > div {
			flex: 1 1 auto;
			margin-left: 20px;
			width: calc(100% / 3 - 40px);
			margin-top: 50px;
		}

		.m-person--featured .m-person__copy {
			padding-top: 60px;
		}

		.m-person__copy {
			display: block;
		}

		.m-form {
			display: flex;
			flex-direction: column;
			> * {
				flex: 1 1 auto;
				margin-bottom: 30px;
			}
		}

		.m-slide__image {
			max-width: 500px;
		}

		.m-card {
			position: relative;
		}
		.m-card-group .m-card {
			padding: 15px;
		}
		.m-card--overlay .m-card__copy {
			position: absolute;
			left: 20px;
			bottom: 20px;
		}

		.m-card__copy {
			max-width: 500px;
		}


		.m-slide {
			display: flex;
			flex-direction: row;
			//min-width: 100%;
		}
		.m-slide__image {
			padding-right: 25px;
			//flex: 0 0 50%;
		}
		.m-slide__copy {
			//flex: 0 0 50%;
			max-width: 50%;
		}

		.m-form__submit-panel {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.m-slider__controls {
			grid-area: controls;
			max-width: 475px;
			margin-left: 0px;
		}

		.m-slider {
			overflow: hidden;
		}

		.t-image-pair {
			max-height: 220px;
		}

		.l-related {
			
		}
	

	}
	
}