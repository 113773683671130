
/**
 * Typography things
 */

@font-face {
  font-family: 'Bebas';
  src: url('../fonts/Bebas-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/Bebas-Regular.otf')  format('opentype'),
	     url('../fonts/Bebas-Regular.woff') format('woff'), url('../fonts/Bebas-Regular.ttf')  format('truetype'), url('../fonts/Bebas-Regular.svg#Bebas-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css?family=Crimson+Text&display=swap');

body {
	-webkit-font-smoothing: antialiased;
}

/**
 * Text colour overrides
 */

.text--dark-grey {
	color: $darkGrey;
}

.text--med-grey {
	color: $medGrey;
}

.text--light-grey {
	color: $lightGrey;
}

.text--grey {
	color: $grey;
}

.text--grey2 {
	color: $grey2;
}

/**
 * Text style overrides
 */
.text--italic {
	font-style: italic;
}


.text--slider-large {
	color: $sliderGrey;
	font-size: 3.6rem;
	font-family: $serif;
	line-height: 4.5rem;
}

.text--slider-small {
	color: $sliderGrey;
	font-family: $serif;
	font-size: 1.8rem;
	line-height: 2.5rem;
}

// Sans headings
//sanshero: 64/77 28/34;
//sans1: 36/43 28/34;
//sans1sub (serif subtitle): 20/27 20/27 serif;
//sans2: 28/34 28/34;
//sans2sub: 16/22 serif;

// Serif headings
//navtitle: 18/30;
//nav & herosub: 20/27;
//footernav: 18/24 20/27;
//footersubnav: 18/24 18/24;
//mobnav: 28/38;
//mobsubnav: 22/30;
//serif1: 36/49 28/38; (e.g. main page titles)
//serif2: 28/30;
//serif2sub: 22/30;
//serif3: 26/30;
//serif3sub: 20/27;
//serif4: 24/30;

// button: 18/24;
// (h45w172, h60w100%)

// Text
// Large 24/30
// Regular 18/30 
// Regular condensed, slightly less line height 18/28 (white on dark etc?)

.h {}


.h--hero {
	font-size: 6.4rem;
	line-height: 77px;
	font-family: $sans;
	@include mq($until: desktop) {
		font-size: 24px;
		line-height: 34px;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $sans;
}

.h--large {
	font-size: 3.6rem;
	line-height: 4.3rem;
	margin-bottom: 0.75em;
	@include mq($until: tablet) {
		font-size: 2.8rem;
		line-height: 3.4rem;
	}
}

.h--large-serif {
	font-size: 3.6rem;
	line-height: 4.3rem;
	font-family: $serif;
	@include mq($until: tablet) {
		font-size: 2.8rem;
		line-height: 3.8rem;
	}	
}

.h--large-sub {
	font-size: 2rem;
	line-height: 2.7rem;
	margin-bottom: 0.2em;
	font-family: $serif;
	color: #8F8F8F;
	@include mq($until: tablet) {
		// same
	}
}


.h--med {
	font-size: 2.8rem;
	line-height: 3.4rem;
	margin-bottom: 0.6em;
	@include mq($until: tablet) {
		font-size: 2.8rem;
		line-height: 3.4rem;
	}
}


.h--med-serif {
	font-size: 2.8rem;
	line-height: 3.0rem;
	margin-bottom: 0.6em;
	font-family: $serif;
	@include mq($until: tablet) {
		font-size: 2.8rem;
		line-height: 3.4rem;
	}	
}

.h--med-sub {
	font-size: 2rem;
	line-height: 2.7rem;
	margin-bottom: 0.2em;
	font-family: $serif;
	color: $grey;
	@include mq($until: tablet) {
		// same
	}
}

.h--small-serif {
	font-size: 2.6rem;
	line-height: 3rem;
	font-family: $serif;
	color: $darkGrey2;
	@include mq($until: tablet) {
		// same
	}
}



.h--tiny-serif {
	font-size: 2.2rem;
	line-height: 3rem;
	font-family: $serif;
	color: $darkGrey2;
	@include mq($until: tablet) {
		// same
	}
}



.text{}


.text--center {
	text-align: center;
}

.text--large {
	font-size: 2.4rem;
	line-height: 3.4rem;
	font-family: $serif;
}

.text--regular {
	font-size: 1.8rem;
	line-height: 30px;
	font-family: $serif;
}

.text--regular-condensed {
	font-size: 1.8rem;
	line-height: 28px;
	font-family: $serif;
}

.text--small-serif {
	font-size: 1.6rem;
	line-height: 2.2rem;
	font-family: $serif;
}


.text--button {
	font-size: 1.8rem;
	line-height: 2.4rem;
}

.text--nav {
	font-size: 2rem;
}

.text--mob-nav {
	font-size: 2.8rem;
	line-height: 3.8rem;
}

.text--mob-subnav {
	font-size: 2.2rem;
	line-height: 3.0rem;
}


// footaddress 22/30 22/30
// footcontact 22/27 22/27

// text derived from wysiwyg
.text--formatted {
	h1, h2, h3 {
		font-family: $serif;
		font-size: 2.8rem;
		line-height: 3rem;
		margin-bottom: 0.75em;
	}
}