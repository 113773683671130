


select {

	appearance: none;
	position: relative;
	width: 100%;

	background: url(../img/icons/select-caret.svg);
	background-position: 100% center;
	background-repeat: no-repeat;
	background-size: 15px 15px;
}

input, select, textarea {
	&:focus {
		outline: none;
		border-color: $blue !important;
	}
}

.m-form {

	.errors {
		li {
			color: #EF4646;
		}
	}

	display: grid;
	grid-gap: 30px;

	label {
		display: flex;
		flex-direction: column;
		color: $blue;
		font-weight: bold;
	}

	input:not([type='submit']), select {

		margin-top: 9px;
		line-height: 69px;
		height: 69px;
		appearance: none;
		border: 1px solid #D1D1D1;
		border-radius: 0px;
		font-family: $serif;
		font-size: 18px;
		color: #868686;
		border: 1px solid #D1D1D1;
		//@include mq(tablet) {
		text-indent: 23px;
		//}

	}

	textarea {
		margin-top: 9px;
		font-family: $serif;
		appearance: none;
		font-size: 18px;
		color: #868686;
		padding: 23px;
		border-radius: 0px;
		border: 1.2px solid #D1D1D1;
	}

}

.m-form__input {
	width: 100%;
}

.m-form__submit-panel {
	position: relative;
	@include mq(desktop) {
		display: grid;
		grid-template-columns: repeat(3, calc(100%/3));
		nth-child(2) {
			grid-column: 2;
		}
		nth-child(3) {
			grid-column: 3;
			text-align: right;
		}
		// use invisible flex element to achieve centering of button!
		/*justify-content: space-between;
		align-items: center;
		.note {

			margin: 0;
		}*/
	}

}

@include mq(tablet) {
	
	.m-form__input--full {
		grid-column: 1 / 3;
	}

	.m-form__submit-panel {
		grid-column: 1 / 3;
	}

}