 /**
  *
  * Smaller adjustments go in here, e.g. adding 10px of padding to a specific element
  * on the homepage, or smaller alterations to how a module appears
  * 
  */

 /**
  * Theme
  */
 
html {	
	font-size: 62.5%;
	-webkit-font-smoothing: subpixel-antialiased;
}

body {
	margin: 0;
	padding: 0;
	font-size: 18px;
	font-family: $serif;
}

body.blackout .t-content {
	&:before {
		pointer-events: none;
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 250;
		top: 0px;
		left: 0px;
		content: "";
		background: rgba(0, 0, 0, 0.5);
	}
}

address, li {
	line-height: 2.7rem;
}


a {
	color: $blue;
	text-decoration: none;
}

.l-content {
	p, ul {
		margin-bottom: 1em;
		line-height: 3rem;
		color: $darkerGrey;
	}

	ul li:before {
		content: "- ";
	}

}



img { 
	width: 100%;
}

/**
 * Inputs
 */

select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}


/**
 * Global template changes
 */

.t-hr {
	width: 129px;
	border: none;
	height: 1px;
	background: $blue;
	margin: 20px 0;
}

.t-hr--light {
	background: white;
}

.t-hr--full {
	width: 100%;
}

.t-hr--grey {
	background: #D5D5D5;
}

.t-list {
	display: flex;
	flex-direction: row;
	li:not(:first-child) {
		margin-left: 28px;
	}
}

.t-tagline {
	//font-weight: 600;
}

.t-logo--header {
	.t-tagline {
		margin-left: 40px;
		@include mq(mobile, tablet) {
			display: none;
		}
	}
}

.t-container--sub .m-hero__content {
	@include mq($from: tablet) {
		top: 0px;
	}
}


/**
 * General layout adjustments
 */

.t-cta-after {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 160px;
	> * {
		position: relative;
		top: 10%;
	}
}

.t-header__nav {
	background: white;
	//height: calc(100vh - 100px);
	padding: 0 40px;
	@include mq(mobile, desktop) {
		border-bottom: 2px solid $lightGrey;
	}
	z-index: 500;
}

.t-header__blackout {
	height: calc(100vh - 100px);
	top: 100px;
	width: 100vw;
	display: none;
	position: fixed;
	left: 0px;
	background: rgba(0, 0, 0, 0.5);
	z-index: 200;
}

.t-header__burger {

	width: 30px;
	height: 30px;

	cursor: pointer;

	position: relative;
	@include mq(desktop) {
		display: none;
	}

	svg {
		width: 100%;
		height: 100%;
	}

}

#headertoggle {
	display: none;
}

#headertoggle:not(:checked) ~ label {
	svg.on {
		display: none;
	}
	svg.off {
		display: block;
	}
}

#headertoggle:checked ~ label {
	svg.on {
		display: block;
	}
	svg.off {
		display: none;
	}
}



// Appropriate spacing for any first-child intro block under a search box
// (but not on home page)
.t-container--sub .t-content--with-search > .t-intro:first-child {
	@include mq(desktop) {
		margin-top: 30px;
	}
}

.t-block {
	@include mq(desktop) {
		margin: 50px 0 0 0;
	}
	@include mq(mobile, desktop) {
		margin: 0 0 25px 0;
	}	
	max-width: 1080px;
	width: 100%;
	position: relative
;
	// Apply spacing in last block between content and footer
	&:last-child {
		padding-bottom: 100px;
	}



	@include mq(mobile, tablet) {
		&:first-child {
			padding-top: 40px;
		}
	}

}

/*.t-container--sub .t-block:first-child {
	margin-top: 0px;
}*/

.t-block--filled {
	background: #F8F8F8;
	padding: 40px 0;
	&:before, &:after {
		content: "";
		background: #F8F8F8;
		height: 100%;
		top: -1px;
	}
	&:before {
		position: absolute;
		left: calc(0px - 50vw + 50%);
		width: calc(50vw - 50%);
	}
	&:after {
		position: absolute;
		left: 100%;
		width: calc(50vw - 50%);
	}
}

.t-block--wide {
	max-width: 1170px;
}

.t-block--full {
	max-width: 9999px;
}

.t-block__heading {
	text-align: center;
	margin-bottom: 2em;
	@include mq(mobile, tablet) {
		margin-bottom: 1em;
	}
}

// logo
.t-logo {
	img {
		//height: auto;
		width: auto;
	}
	/*display: grid;
	grid-template-areas: "logo-image" "logo-text";
	grid-template-rows: 50px 18px;
	grid-row-gap: 5px;
	text-align: center;*/
	display: flex;
	flex-direction: column;
	
	align-items: center;
	justify-content: center;
}

// Hero mods
.t-hero__contact-methods {
	text-transform: none;
	@include mq(mobile, desktop) {
		display: none;
	}
	@include mq(tablet, desktop) {
		ul {
			flex-direction: column;
			li {
				margin-bottom: 0.25em;
			}
		}
	}
}

// Intro mods
.t-intro {
	max-width: 640px;
	@include mq(desktop) {
	min-width: 640px;
	}
	
	font-size: 2.4rem;
	p {
		font-size: inherit !important;
	}
}

.t-intro ~ .t-copy-column {
	margin-top: 0px;
}

.t-container--sub:not(.t-container--has-parent) .t-intro {
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		background: #EAEAEA;
		margin: 20px 0 0 0;
	}
	
}

// Slider mods
// slider on home page always takes up full width
.t-slider--home-page {
	max-width: 100%;
	.m-slide {
		background: transparent;
	}
}

.t-card-group--home-page {
	margin-top: 0px;
	@include mq(desktop) {
		&, &:before, &:after {
			border-top: 1px solid #D0D0D0;
		}
	}
}

// Copy content mods

.t-copy-column {
	max-width: 600px;

	figure {
		max-width: 50%;
		float: left;
	}
}

// if an image pair appears directly after a copy column, adjust width and remove heading
.t-copy-column ~ .t-image-pair {
	max-width: 600px;
	margin: 40px 0;
	padding: 30px 0;
	border-top: 1px solid #EAEAEA;
	border-bottom: 1px solid #EAEAEA;
	.t-block__heading {
		display: none;
	}
}

.t-properties {
	max-width: 1092px;
}

/**
 * Homepage template
 */


.t-container--home-page {
	.m-slider__slide {
		background: transparent;
	}
}

.t-intro--home-page {	
	// larger padding on homepage
	margin: 110px 0 80px 0;
	@include mq(mobile, tablet) {
		margin: 50px 0 65px 0;
	}
	// centered intro only on homepage
	text-align: center;
} 

.t-slider--homePage {
	padding-bottom: 100px;
}

.t-card-group--homePage {
	max-width: 1020px;
}

.t-card-group--subPage {
	max-width: 1120px;
}


/**
 * Contact page
 */

.t-form--contact-page {
	@include mq(desktop) {
		min-width: 880px;
	}
	@include mq(mobile, desktop) {
		width: 100%;
		max-width: 880px;
	}

}

/*.t-homePage__services {
	max-width: 1230px;
}

.t-homePage__properties {
	max-width: 900px;
	padding: 100px 0;
}*/


/**
 * Properties page
 */

.t-container--properties-page, .t-container--meet-our-team-page {
	background: #F8F8F8;
}


/**
 * Team page
 */

.t-meet-our-team {
	@include mq(mobile, tablet) {
		//margin-top: 50px;
		.m-people {
			margin-top: 100px;
		}
	}
	@include mq(tablet, desktop) {
		//margin-top: 100px;
	}
	@include mq(tablet) {
		//margin-top: 30px;
	}

}


/**
 * Footer template
 */


.t-footer {
	background: $blue;
	
	@include mq(mobile, tablet) {
		padding: 50px 0px;
	}

	@include mq(tablet) {
		padding: 85px 0px;
		//margin-top: 100px;
	}
	
	* {
		color: white;
	}
	img {
		width: auto;
		display: block;
	}
}

.t-footer__logo {
	img {
		padding-bottom: 5px;
	}
}

.t-footer__nav, .t-footer__subnav {

	ul {
		display: flex;
		
		@include mq(tablet) {
			justify-content: space-between;
			flex-direction: row;	
		}

		@include mq(mobile, tablet) {
			flex-direction: column;
		}
	}
}

.t-footer__social {
	.t-ul {
		@include mq(tablet) {
			justify-content: flex-end;
		}
		flex-direction: row;
		li:not(:last-child) {
			margin-right: 14px;
		}
	}
	.t-icon:before {
		width: 30px;
		height: 30px;
	}
}


// ul styles

.t-ul {

	display: flex;
	flex-direction: column;
	
	li {
		//margin-bottom: 1.7rem;
	}
}

.t-ul--horiz {
	display: flex;
	flex-direction: row;
	> li:not(:last-child) {
		margin-right: 1em;
	}
}

// icons
.t-icon {
	display: flex;
	flex-direction: row;
	align-items: center;
	/*justify-content: center;
	*/
	//line-height: 1;
	&:before {
		content: "";
		position: relative;
		display: block;
		width: 14px;
		height: 14px;
		top: 2px;
		background-color: transparent;
		background-size: contain;
		background-position: center;
		margin-right: 0.4em;
	}
}

.t-icon--linkedin:before {
	background-image: url(../img/icons/linked-in.svg);
}

.t-icon--phone:before {
	background-image: url(../img/icons/phone.svg);
}

.t-icon--email:before {
	background-image: url(../img/icons/email-header.svg);
}