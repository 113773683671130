.m-image-pair {
	display: grid;
	@include mq(tablet) {
		grid-template-areas: "image1 image2";
		grid-template-columns: 1fr 1fr;
	}
	@include mq(mobile, tablet) {
		grid-template-areas: "image1" "image2";
		grid-template-rows: 1fr 1fr;
	}
	grid-gap: 16px;
}

.m-image-pair > :nth-child(1) {
	grid-area: image1;
}

.m-image-pair > :nth-child(2) {
	grid-area: image2;
}